export const WORDS = [
  'ready',
  'sight',
  'chain',
  'rollr',
  'coach',
  'ownit',
  'ultra',
  'tests',
  'focus',
  'otter',
  'flyer',
  'sport',
  'cycle',
  'agile',
  'draft',
  'drops',
  'crank',
  'storm',
  'tempo',
  'hikes',
  'steel',
  'mario',
  'llama',
  'alert',
  'forks',
  'chest',
  'cleat',
  'crazy',
  'sorba',
  'pinch',
  'tickr',
  'train',
  'stage',
  'bolts',
  'desks',
  'minis',
  'plans',
  'pacer',
  'crush',
  'zones',
  'laser',
  'oneby',
  'horse',
  'timer',
  'shift',
  'cleat',
  'agony',
  'betas',
  'monty',
  'grade',
  'group',
  'gears',
  'flume',
  'track',
  'model',
  'beers',
  'level',
  'racer',
  'voice',
  'loops',
  'smart',
  'ditch',
  'sleep',
  'meter',
  'glory',
  'strap',
  'gathr',
  'glute',
  'snaps',
  'watch',
  'rides',
  'dames',
  'chase',
  'comps',
  'tread',
  'aeros',
  'water',
  'goats',
  'drill',
  'clubs',
  'steer',
  'pedal',
  'cotty',
  'float',
  'power',
  'roams',
  'systm',
  'clips',
  'pages',
  'ronde',
  'otter',
  'badge',
  'turbo',
  'alpha',
  'stats',
  'cross',
  'brick',
  'neuro',
  'rflkt',
  'wahoo',
  'route',
  'alloy',
  'cores',
  'roads',
  'event',
  'bends',
  'build',
  'magic',
  'rival',
  'start',
  'nanos',
  'value',
  'axles',
  'climb',
  'fondo',
  'quick',
  'block',
  'graph',
  'surge',
  'goals',
  'motor',
  'honor',
  'wrist',
  'habit',
  'bikes',
  'wheel',
  'taper',
  'dares',
  'share',
  'cloud',
  'trail',
  'allos',
  'grunt',
  'bunny',
  'split',
  'kickr',
  'blend',
  'carbs',
  'watts',
  'light',
  'speed',
  'video',
  'mount',
  'drill',
  'steps',
  'break',
  'rally',
  'zeros',
  'trick',
  'prime',
  'novid',
  'brake',
  'dirty',
  'allez',
  'poses',
  'jumps',
  'trial',
  'fixie',
  'miles',
  'final',
]
